@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Nunito;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
.ant-layout-sider-children, .ant-layout{
  background: #fff;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-layout-content-static{
  position: static;
  padding-left: 200px;
  padding-top: 40px;
}

.ant-layout-header {
  width: 87.5%;
  margin-left: 200px;
  z-index: 1000;
  top:0;
}

.site-page-header {
  border: none;
}

.ant-page-header-back {
  margin-right: 8px;
  font-size: 16px;
  line-height: 2;
}

.ns-bg-light-white{
  background-color:#f7f7f7;
}

.antd-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.ns-text-light-white{
  color:#f7f7f7;
}

.ant-statistic-title {
  margin-bottom: 4px;
  color:rgba(38, 38, 38, 0.85);
  font-size: 22px;
  font-weight: bold;
}

.personal-statistic .ant-statistic-title {
  font-size: 18px;
  font-weight: bold;
}
.personal-statistic .ant-card-body{
  padding:6px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right:none;
}

.ant-layout-sider-children{
  border-right: .5px solid #e2e8f0;
}

.custom-class{
  height: 500px;
  width: 800px;
  white-space: pre-wrap;
}
.Spam-Rate {
  /* width: 152px; */
  height: 41px;
  font-family: Nunito;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(38, 38, 38, 0.85);
}

.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
  color: #60c72a;
  font-size: 32px;
  font-weight: 500;
}
.personal-statistic .ant-statistic-content-value{
  font-size: 28px;
  font-weight: 500;
}

.individual-statistic .ant-statistic-content-value{
    background-color:#f7f7f7;
    border-radius: 12px;
    padding: 11px 52px;
    margin-top:52px;
}

.ant-layout-header .ant-page-header-heading-title{
  font-size:30px;
  line-height: 39px;
}

.ant-menu-item-last-child {
  margin-top: 525px;
}

.ant-menu-sub {
  overflow-y: auto;
  height: 100vh;
}

.v-align-3 .anticon{
  vertical-align: 3px;

}
.v-align-2 .anticon{
  vertical-align: 2px;
}

.ns-content-section{
  min-height: 325px;  
}

.ns-sider-min-height{
  min-height: 750px;
}

.signup-img {
  height: 800px;
  background-color: #e9f0f6;
}

.Welcome-Please-sign {
  width: 375px;
  height: 24px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
}

.On-Allegrow-weve-s {
  width: 375px;
  height: 72px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
}

.ant-modal-content{
  border-radius: 25px;
}

.ant-modal-close{
  display: none;
}

.please-type-in-your {
  color:#505050;
}

.ns-absolute{
  position: absolute!important;
}

.ns-green{
  color: #60c72a;
}

.ns-bg-green{
  background-color: #60c72a;
}
.ns-bg-green-dark{
  background-color: #207402!important;
}

.ns-bd-green{
  border: solid 3px #60c72a!important;
}

.ns-bg-green .ant-btn:hover, 
.ns-bg-green .ant-btn:focus {
  background-color: #60c72a;
}
.ns-gray{
  color:#8c8c8c;
}

.ns-w-275{
  width: 275px;
}

.ns-shadow-xl{
  -webkit-box-shadow: 5px 3px 20px 3px rgba(219,219,219,0.79);
  -moz-box-shadow: 5px 3px 20px 3px rgba(219,219,219,0.79);
  box-shadow: 5px 3px 20px 3px rgba(219,219,219,0.79);
}

.ns-shadow-xl-skip-top{
  -webkit-box-shadow: 5px 0px 20px 3px rgba(219,219,219,0.79);
  -moz-box-shadow: 5px 0px 20px 3px rgba(219,219,219,0.79);
  box-shadow: 5px 12px 20px 3px rgba(219,219,219,0.79);
}

.ns-shadow-white-xl{
  -webkit-box-shadow: 5px 3px 20px 3px rgba(245,245,245,0.79);
  -moz-box-shadow: 5px 3px 20px 3px rgba(245,245,245,0.79);
  box-shadow: 5px 3px 20px 3px rgba(245,245,245,0.79);
}
.ns-rounded-lg{
  border-radius: 25px!important;
}

.ant-popover-inner{
  border-radius: 25px!important;
}

.ns-rounded-bl-lg{
  border-bottom-left-radius: 25px;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#root .logo {
  float: left;
}

.line-chart {
	animation: fadeIn 600ms cubic-bezier(.57,.25,.65,1) 1 forwards;
  opacity: 0;
}

.line-chart-aspect-ratio {
  height: 0;
  padding-bottom: 25%;
  cursor: pointer; 
}

.activeChartCard {
  border: solid 3px #69bb24;

}

.chartCard .ant-card-bordered:not(.activeChartCard){
  border: solid 3px #fff;
}



/* Collaspse Panel */
.collapse-panel-title span:not(.ant-collapse-content-inactive){
  margin-top: 4px;
}
.ant-collapse-header span{
  float: left;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(38, 38, 38, 0.85);
}

.collapse-panel-content {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
}

.collapse-panel-content-archived {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(191, 189, 189, 0.85) !important;
}

.ant-collapse-extra button{
  display: none;
}

.ant-collapse-item-active .ant-collapse-extra button{
  display: block;
}

.ns-mb-0{
  margin-bottom: 0px!important;
}

.ns-p-0{
  padding: 0px!important;
}

.herder-div-width{
  width:calc(100% - 200px);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.connect-mailbox-modal {
  transform-origin: center!important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.screen-middle-public {
  height: 65vh;
  display:grid;
  align-content: center;
}

.stick-bottom-footer{
  position: absolute;
  display: block;
  margin-left: 40%;
}

.ant-message .anticon{
  top:-3px;
}

.space-align-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.space-align-block {
  margin: 8px 4px;
  border: 1px solid #40a9ff;
  padding: 4px;
  flex: none;
}

.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}

.h-500 {
  height: 500px;
}

.ns-bottom {
  position: absolute;
  bottom: 35px;
}

.ns-right-12 {
  right: 12%;
}

.ns-left-10 {
  left: 10%;
}
.ns-left-5 {
  left: 5%;
}

.Reputation {
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  color: #a13cc8;
}

.clef-replies, .clef-emails {
  width: 102px;
  color: #ff6a00;
}

.dorian-emails {
  width: 82px;
  color: #353fc3;
}

.dorian-replies {
  color: #51b200;
}

.dorian-replies-important {
  color: #51b200!important;
}

.motif-emails {
  width: 91px;
  color: #4cb8c4;
}

.info-emails {
  width: 20px; 
  margin: 4px 364px 25px 20px;
  color: #a6a6a6;
  display: inline-grid;
  position: absolute;
  padding-top: 14px;
}

.multi-type-emails span{
  height: 24px;
}
.Allegrow-Email-Categ {
  margin: 0 480px 8px 22px;
  font-size: 17px;
  color: #505050;
}

.The-Allegrow-platforMargin {
  width: auto;
  margin: 17px 43px 0 22px;
  font-size: 16px;
}
.The-Allegrow-platfor {
  margin: 5px 10px 0 22px;
  font-size: 15px;
  color: #505050;
}

.The-Allegrow-platfor .text-style-1 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.ant-input-textarea-show-count::after {
  content: attr(data-words) " words, " attr(data-count)" characters";
}

.ant-popover-title{
  min-height: 42px;
  padding: 10px 16px 4px;
}

.spamRateCard {
  color: #AC105C;
}

.chartjs-size-monitor { 
  z-index: 9999!important
}

#tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 4px;
}

#tooltip td {
  text-align: left;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}


.create-content-tests .ant-statistic-content-prefix {
  margin-right: 0px;
}
.create-content-tests .ant-statistic-content-prefix .ant-form-item{
  margin-bottom: 0px;
}

.mailbox-shrink h4,.mailbox-shrink div div{
  text-align: left;
  margin-left: 12%;
}

.ns-cursor-disabled{
  color: #000 !important;
  background-color: #fff !important;
}

.ant-picker-dropdown-placement-bottomRight{
  top: 207px!important;;
}

.ant-statistic-content-prefix input {
  width: 35px;
  font-size: 26px;
  padding: 0px;
  color: rgba(38, 38, 38, 0.85);
  font-weight: 200;
}

.create-content-tests .statistics-percentage-card .ant-statistic-content-value {
  position: absolute;
  left: 57%;
  margin-top: -2px;
}
.chartCardContent .variat_1 .ant-statistic-content-value{
  color: #FF6A00!important;
}

.statistics-joined-card  .ant-statistic-content-value {
  font-size: 26px;
  font-weight: 200;
  color: #262626d9 !important;
}

.statistics-joined-card .ant-statistic-content-value:not(.statistics-joined-card-seleced){
  /* border: solid 2px #e2e8f0; */
  border-radius: 15px;
  padding: 2px;
}

.statistics-joined-card-seleced .ant-statistic-content-value {
  border: 1px solid #51b200;
  border-radius: 15px;
  padding: 2px;
}

.statistics-joined-card .ant-card-body {
  display: flex;
  justify-content: space-between;
}

.ant-picker-suffix, .ant-picker-active-bar{
  display: none !important;
}

.date-range-picker input{
  font-weight: 200;
  font-size: 26px;
  text-align: center;
}

.date-range-card .ant-form-item-control{
  margin: 0!important;
}

.date-range-picker .ant-picker-input input{
  cursor: pointer;
}
.date-range-picker {
  /* margin-top: -41px; */
  /* display: flex;
  flex-direction: row; */
  
  /* position: absolute; */
  /* z-index: -1; */
}

#control-hooks_variat_a_subject, #control-hooks_variat_b_subject,
#control-hooks_variat_a_body, #control-hooks_variat_b_body
{
  border-radius: 5px;
}
.variations-title{
  font-size: 25px;
  font-weight: 500;
}
.variations-title.variat_0 {
  color: #51b200!important;
}

.variations-title.variat_1 {
  color: #FF6A00!important;
}

.save-as-draft{
    display: flex;
    float: left;
}

.create-content-tests .ant-select-selector {
  border-radius: 5px !important;
  height: 40px!important;
  font-size: 16px;
  text-align: left;
  padding-top: 4px !important;
  cursor: pointer !important;
}

.variations-cal div{
  /* padding-left: 0px; */
  flex: 1;
}


/* .variations-cal-head {
  float: right;
  width: 47%;
} */

.ns-pd-r-50{
  padding-right: 50px;
}

/* .variations-cal-head .variations-reputation, .variations-cal-head .variations-spam-rate, .variations-cal-head .variations-difference{
  text-align: center;
} */

/* .variations-cal-head .variations-email-sent{
  padding-left: 8%;
} */

/* .variations-cal-head .variations-reputation{
  padding-left: 6%;
}

.variations-cal-head .variations-spam-rate{
  padding-left: 3%;
} */

.variations-cal-head .variations-difference{
  /* padding-left: 3%; */
}

.variations-cal-head div{
  color: #262626a8;
  font-size: 16px;
  font-weight: 200;
  /* width: 100px; */
  flex: 1;
  text-align: center;
}

.variations-cal div{
  /* display: flex;
  width: 80px;
  align-items: center; */
  color: #262626a8;
  font-size: 22px;
  font-weight: 500;
  flex: 1;
  text-align: center;
}

/* .lab-icon .ant-menu-item-selected{
  fill:#e2e8f0
}

.lab-icon .ant-menu-item-selected{
  fill:#e2e8f0
} */


.my-profile .ant-input-affix-wrapper{
  border-radius: .5rem;
  height: 34px;
  padding: 4px 4px 4px 0px;
}

.my-profile .ant-select-selector{
  cursor: pointer !important;
}

.my-profile .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
  border: #fff!important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.ns-menu {
  animation: fadeIn 0.2s ease-in-out;
}


/* .ant-popover-inner-content{
  font-family: 'Nunito Sans';
  font-weight: 200;
} */
/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
/* .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
} */

@media (min-width: 1024px) {
  .mailbox-shrink h4,.mailbox-shrink div div{
    margin-left: 12%;
  }
}

@media (min-width: 1800px) {
  .mailbox-shrink h4,.mailbox-shrink div div{
    margin-left: 14%;
  }
}